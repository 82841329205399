<template>
  <div id="Header">
    <header class="header header-transparent uk-light"
      uk-sticky="top:20 ; cls-active:header-sticky ; cls-inactive: uk-light2">
      <div class="container position-relative">
        <nav uk-navbar>
          <!-- left Side Content -->
          <div class="uk-navbar-left">
            <span class="btn-mobile" uk-toggle="target: #wrapper ; cls: mobile-active"></span>
            <!-- logo -->
            <router-link class="logo" :to="{ name: 'user.index' }">
              <img :src="loginLogo" alt="" />
              <div class="responsive"></div>
            </router-link>
          </div>

          <!--  Right Side Content   -->

          <div class="uk-navbar-right">
            <p v-if="online_users.length" class="online d-none d-sm-block" @click="showUser()" style="cursor:pointer">
              <i class="uil-users-alt"></i> {{ online_users.length }}
              {{ $t("home.Person_Online") }}
            </p>
            <div class="header-widget">
              <!-- <div style="width: 5rem;margin-top: 1rem">
                <input name=" Color Picker" type="color" v-model="colorPick" v-on:input="changeColor"    />
              </div> -->
              <!-- User icons close mobile-->
              <span class="icon-feather-x icon-small uk-hidden@s" uk-toggle="target: .header-widget ; cls: is-active">
              </span>
              <a :uk-tooltip="`title: Kullanıcı Sayfasından çıkış yap`" style="color:red" class="header-widget-icon"
                v-if="superAdmin() && superAdminToken" @click="leaveFromUser()">
                <i class="uil-eye"></i>
              </a>
              <a :uk-tooltip="`title: ${$t('home.Notifications')}  ; pos: bottom ;offset:0`
                " class="header-widget-icon" href="#">
                <i class="uil-bell"></i>
                <span v-if="notificationCount > 0" :class="notificationCount > 99 ? 'big' : ''">{{
                  notificationCount
                }}</span>
              </a>

              <!-- notificiation dropdown -->
              <div ref="notificationRef" class="dropdown-notifications"
                uk-dropdown="pos: top-right;mode:click; animation: uk-animation-slide-bottom-small">
                <!-- notivication header -->
                <div class="dropdown-notifications-headline">
                  <h4>{{ $t("home.Notifications") }}</h4>
                </div>

                <!-- notification contents -->
                <div class="dropdown-notifications-content" data-simplebar>
                  <!-- notiviation list -->
                  <ul>
                    <template v-for="(item, index) in notificationItems">
                      <li v-if="'header_notification_item_' + index" :key="'header_notification_item_' + index">
                        <template v-if="typeof item.parsed_data.webUrl !== 'undefined' &&
                          item.parsed_data.webUrl &&
                          typeof item.parsed_data.webUrl.name !==
                          'undefined' &&
                          item.parsed_data.webUrl.name !== '' &&
                          typeof item.parsed_data.webUrl.params !==
                          'undefined'
                        ">
                          <a href="#" @click.prevent="
                            closeDropdown(
                              item.parsed_data.webUrl.name,
                              'notificationRef',
                              item.parsed_data.webUrl.params,
                              item.id
                            )
                            ">
                            <span class="notification-icon btn btn-soft-info"><i class="icon-feather-bell"></i></span>
                            <span class="notification-text" :class="{ 'font-weight-bold': !item.read_at }">
                              <template v-if="typeof item.parsed_data.message !==
                                'undefined'
                              ">{{ item.parsed_data.message }}</template>
                              <template v-else>Bir bildiriminiz var</template>
                              <span class="time-ago d-block">{{
                                fixDateToNow(
                                  item.updated_at
                                    ? item.updated_at
                                    : item.created_at
                                )
                              }}</span>
                            </span>
                          </a>
                        </template>
                        <template v-else>
                          <a @click="
                            closeDropdown(
                              'dashboard.notification',
                              'notificationRef',
                              null,
                              item.id
                            )
                            ">
                            <span class="notification-icon btn btn-soft-info"><i class="icon-feather-bell"></i></span>
                            <span class="notification-text" :class="{
                              'font-weight-bold':
                                item &&
                                item.pivot &&
                                +item.pivot.is_read === 0,
                            }">
                              <template v-if="typeof item.parsed_data.message !==
                                'undefined'
                              ">{{ item.parsed_data.message }}</template>
                              <template v-else>Bir bildiriminiz var</template>
                              <span class="time-ago d-block">{{
                                fixDateToNow(
                                  item.updated_at
                                    ? item.updated_at
                                    : item.created_at
                                )
                              }}</span>
                            </span>
                          </a>
                        </template>
                      </li>
                    </template>
                  </ul>
                </div>

                <div class="dropdown-notifications-footer">
                  <a href="javascript:void(0)" @click="
                    closeDropdown('dashboard.notification', 'notificationRef')
                    ">
                    {{ $t("home.see_all") }}
                    <i class="icon-line-awesome-long-arrow-right"></i>
                  </a>
                </div>
              </div>

              <!-- Message  -->
              <a :uk-tooltip="`title: ${$t('home.Messages')} ; pos: bottom ;offset:0`
                " class="header-widget-icon" href="javascript:void(0)">
                <i class="uil-envelope-alt"></i>
                <span v-if="itemCount > 0">{{ itemCount }}</span>
              </a>

              <!-- Message  notificiation dropdown -->
              <div ref="messageRefs" class="dropdown-notifications" uk-dropdown=" pos: top-right;mode:click">
                <!-- notivication header -->
                <div class="dropdown-notifications-headline">
                  <h4>{{ $t("home.Messages") }}</h4>
                </div>

                <!-- notification contents -->
                <div class="dropdown-notifications-content" data-simplebar>
                  <!-- notiviation list -->
                  <ul>
                    <li v-for="(item, index) in messageItems" :key="index">
                      <a @click="
                        closeDropdown('User.messages', 'messageRefs', item)
                        ">
                        <span class="notification-avatar">
                          <img :alt="item.fullname" :src="item.user?.photo_link" />
                        </span>
                        <div class="notification-text notification-msg-text">
                          <strong>{{ item.fullname }}</strong>
                          <p>{{ item.message }}</p>
                          <span class="time-ago d-block">{{
                            fixDateToNow(item.date)
                          }}</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="dropdown-notifications-footer">
                  <a @click="closeDropdown('User.messages', 'messageRefs')">
                    {{ $t("home.see_all") }}
                    <i class="icon-line-awesome-long-arrow-right"></i>
                  </a>
                </div>
              </div>

              <!-- Language  -->
              <a :uk-tooltip="`title: ${$t('home.languages')} ; pos: bottom ;offset:0`
                " class="header-widget-icon" href="javascript:void(0)">
                <img class="h-23px w-23px rounded-sm" :src="languageFlag || getLanguageFlag" alt="" />
              </a>
              <!-- Language dropdown -->
              <div ref="languageRefs" class="dropdown-notifications dropdown-languages"
                uk-dropdown=" pos: top-right;mode:click">
                <!-- notification contents -->
                <div class="dropdown-notifications-content" data-simplebar>
                  <!-- notiviation list -->
                  <ul style="background-color: white;">
                    <template v-for="(item, i) in languages">
                      <li v-if="item.lang !== activeLanguage" class="navi-item" :class="{
                        'navi-item-active': isActiveLanguage(item.lang),
                      }" :key="i">
                        <a href="#" class="navi-link" v-bind:data-lang="item.lang" v-on:click="selectedLanguage">
                          <span class="symbol symbol-20 mr-3">
                            <img :src="item.flag" alt="" class="h-23px w-23px rounded-sm" />
                          </span>
                          <span class="navi-text">{{ item.name }}</span>
                        </a>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>

              <!-- profile-icon-->
              <a class="header-widget-icon profile-icon" href="#">
                <img :src="userInfo?.photo" alt="" class="header-profile-icon" />
              </a>

              <div ref="profileRefs" class="dropdown-notifications small" uk-dropdown="pos: top-right ;mode:click">
                <!-- User Name / Avatar -->
                <a href="#">
                  <div class="dropdown-user-details">
                    <div class="dropdown-user-avatar">
                      <img :src="userInfo?.photo" alt="" />
                    </div>
                    <div class="dropdown-user-name">
                      {{ userInfo?.name }} {{ userInfo?.surname }}
                      <span>{{ userInfo?.company_name }}</span>
                    </div>
                  </div>
                </a>

                <!-- User menu -->
                <ul class="dropdown-user-menu">
                  <li v-if="isAdmin">
                    <a @click="$router.push({ name: 'company.dashboard' })">
                      <i class="uil-briefcase"></i>
                      {{ $t("general.admin_panel") }}
                    </a>
                  </li>
                  <li v-if="isAdmin" class="menu-divider"></li>
                  <li>
                    <a @click="closeDropdown('user.profile', 'profileRefs')">
                      <i class="icon-feather-settings"></i>
                      {{ $t("general.profile") }}</a>
                  </li>
                  <li>
                    <a @click="
                      closeDropdown('User.my-certification', 'profileRefs')
                      ">
                      <i class="uil-award"></i> {{ $t("general.certificates") }}
                    </a>
                  </li>
                  <li class="menu-divider"></li>
                  <!-- <li>
                    <a href="#">
                      <i class="icon-feather-thumbs-up"></i>
                      {{ $t("general.suggest") }}</a
                    >
                  </li> -->
                  <!-- <li>
                    <a href="sss.html">
                      <i class="icon-feather-help-circle"></i>
                      {{ $t("general.faq") }}
                    </a>
                  </li> -->
                  <li>
                    <a @click="closeDropdown('User.agreement', 'profileRefs')">
                      <i class="icon-feather-info"></i>
                      {{ $t("general.legal_notification") }}</a>
                  </li>
                  <li>
                    <a @click="closeDropdown('User.faq')">
                      <i class="icon-feather-help-circle"></i>
                      {{ $t("general.faq") }}</a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="onLogout">
                      <i class="icon-feather-log-out"></i>
                      {{ $t("general.log_out") }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <!-- User icons -->
            <a class="uil-user icon-small uk-hidden@s" href="#" uk-toggle="target: .header-widget ; cls: is-active">
            </a>
          </div>
          <!-- End Right Side Content / End -->
        </nav>

        <div class="toast-container d-none">
          <div class="uk-alert-warning d-flex slide-in" uk-alert>
            <a class="uk-alert-close" uk-close></a><i class="uil-bolt-alt"></i>
            <p>
              <b><a href="#">Ahmet Güler</a></b> adlı kullanıcı seni düelloya
              davet etti. <a href="#">Detayları Görüntüle</a>
            </p>
          </div>
          <div class="uk-alert-success d-flex slide-in" uk-alert>
            <a class="uk-alert-close" uk-close></a><i class="uil-trophy"></i>
            <p>
              'Telefonde Etkili İletişim' eğitimini tamamlayarak
              <b>15 puan</b> kazandın!
            </p>
          </div>
          <div class="uk-alert-primary d-flex slide-in" uk-alert>
            <a class="uk-alert-close" uk-close></a><i class="uil-book-open"></i>
            <p>
              <b>Dijital Terimler: Geleceğin Dünyası</b> eğitimi sana atandı.
              <a href="#">Eğitimi Görüntüle</a>
            </p>
          </div>
        </div>
      </div>
      <!-- container  / End -->
    </header>
    <div id="modal_onlineUsers" class="uk-modal" uk-modal="">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">{{ $t("general.online_person") }}</h2>
          <ol>
            <li v-for="onlineUser in onlineUsers">{{ onlineUser?.full_name }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import NotificationItem from "@/view/components/notification/NotificationItem";
import _ from "lodash";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/az';
import 'moment/locale/tr';
import 'moment/locale/en-gb';
import store from "@/core/services/index";
import { find, remove } from "lodash/fp";
import { GET_ITEMS } from "@/core/services/store/REST.module";
import EventBus from "@/view/components/event-bus";
import i18nService from "@/core/services/i18n.service.js";
import axios from "axios";
import company_module, {
  GET_SELF as COMPANY_GET_SELF,
  MODULE_NAME as COMPANY_MODULE_NAME,
  ONLINE_USERS,
  SELF as COMPANY_SELF,
  SELF_URL as COMPANY_SELF_URL,
  SET_ONLINE_USERS,
} from "@/core/services/store/company.module";

import notification_module, {
  COUNT as NOTIFICATION_COUNT,
  GET_COUNT as NOTIFICATION_GET_COUNT,
  ITEMS as NOTIFICATION_ITEMS,
  GET_ITEMS as NOTIFICATION_GET_ITEMS,
  MODULE_NAME as NOTIFICATION_MODULE_NAME,
} from "@/core/services/store/notification.module";

import Echo from "laravel-echo";

window.io = require("socket.io-client");
import SecureLS from "secure-ls";
const ls = new SecureLS({ encodingType: "aes", isCompression: true });
export default {
  name: "Header",
  components: {
    NotificationItem,
  },
  data() {
    return {
      colorPick: '#e66465',
      onlineUsers: [],
      superAdminToken: null,
      notificationUrl: "api/notifications", //"api/user/notice",
      messagesUrl: "api/user-messages",
      messagesCountUrl: "api/user-messages-count",
      messageItems: [],
      itemCount: 0,
      notifCount: 0,
      languages: i18nService.languages,
      languageFlag: "",
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(COMPANY_MODULE_NAME, company_module);
    registerStoreModule(NOTIFICATION_MODULE_NAME, notification_module);
  },
  computed: {
    user_header: {
      get() {
        return JSON.parse(this.secureLocalStorage("user"));
      },
      set() {
      },
    },
    notificationCount: {
      get() {
        return store.getters[
          NOTIFICATION_MODULE_NAME + "/" + NOTIFICATION_COUNT
        ];
      },
      set(value) {
      },
    },
    notificationItems: {
      get() {
        return store.getters[
          NOTIFICATION_MODULE_NAME + "/" + NOTIFICATION_ITEMS
        ];
      },
      set(value) {
      },
    },
    online_users: {
      get() {
        return store.getters[COMPANY_MODULE_NAME + "/" + ONLINE_USERS];
      },
      set(value) {
      },
    },
    company: {
      get() {
        return store.getters[COMPANY_MODULE_NAME + "/" + COMPANY_SELF];
      },
      set(value) {
      },
    },
    loginLogo() {
      if (this.company == null) return "";
      return this.company.logo_link;
    },
    userInfo: {
      get: function () {
        return this.authUser;
      },
      set: function (newValue) {
      },
    },
    isAdmin() {
      let isAdmin = false;
      if (this.userInfo && this.userInfo.roles && this.userInfo.roles.length) {
        isAdmin = !!this.userInfo.roles.find(
          (item) => item.id == 1 || item.id == 2
        );
      }
      return isAdmin;
    },
    activeLanguage() {
      return i18nService.getActiveLanguage();
    },
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
  },
  methods: {
    changeColor() {
      const root = document.querySelector(':root');
      // root.style.setProperty('--backgroundColor', this.colorPick);
    },
    showUser() {
      UIkit.modal("#modal_onlineUsers").show();
    },
    superAdmin() {
      if (this.user_header === null || !("roles" in this.user_header)) {
        return false;
      }
      if (this.user_header.roles.length > 2) {
        return true;
      } else {
        return false;
      }
    },
    leaveFromUser() {
      axios.delete('api/impersonate/leaving', {
        headers: this.headers
      })
        .then((response) => {
          if (this.superAdminToken) {
            ls.set("token", this.superAdminToken);
            window.localStorage.removeItem('superAdminToken');
            location.reload();
          }
        })
    },
    getNotifCount() {
      let self = this;

      self.$store.dispatch(
        NOTIFICATION_MODULE_NAME + "/" + NOTIFICATION_GET_COUNT,
        {}
      );
    },
    closeDropdown(route, refName, params = {}, itemId = 0) {
      if (refName === "notificationRef") {
        params = _.merge({}, { id: itemId }, params);
      }
      if (refName === "messageRefs") {
        params = _.merge({}, { message: params });
        this.getMessagesCount();
      }
      if (this.$route.name != route) {
        this.$router.push({ name: route, params: params });
      }
      UIkit.dropdown(this.$refs[refName]).hide(0);
    },
    onLogout() {
      window.localStorage.removeItem("redirect_url");
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    myProfile() {
      this.$router.push({ name: "profile.edit" });
    },
    fixDateToNow(value) {
      return moment(value).locale(this.secureLocalStorage("language")).format('DD MMMM YYYY');
    },
    getNotificationItems() {
      let self = this;

      self.$store.dispatch(
        NOTIFICATION_MODULE_NAME + "/" + NOTIFICATION_GET_ITEMS,
        {
          filters: {
            limit: 10,
          },
        }
      );
    },

    getMessages() {
      let self = this;
      self.$store
        .dispatch(GET_ITEMS, {
          url: this.messagesUrl,
          filters: {},
          acceptPromise: false,
        })
        .then((response) => {
          let items = response.data;
          items.forEach((item) => {
            let chatUsers = item.group.users;
            item.user = chatUsers.find(
              (subItem) => subItem.id === item.sender_user_id
            );
          });
          self.messageItems = items.reverse();
        });
    },
    getMessagesCount() {
      let self = this;
      self.$store
        .dispatch(GET_ITEMS, {
          url: this.messagesCountUrl,
          filters: {},
          acceptPromise: false,
        })
        .then((response) => {
          self.itemCount = response.data;
        });
    },
    async getCompanyItem() {
      if (this.company == null || this.company.logo_link == undefined)
        await this.$store.dispatch(COMPANY_MODULE_NAME + "/" + COMPANY_GET_SELF, {
          url: COMPANY_SELF_URL,
        }).then((response) => {
          const root = document.querySelector(':root');
          let settings = response?.settings.find(x => x.key === 'theme_settings');
          settings ? root.style.setProperty('--iconColor', settings?.value?.iconColor) : root.style.setProperty('--iconColor', '#93949c');
          settings ? root.style.setProperty('--backgroundColor', settings?.value?.backgroundColor) : root.style.setProperty('--backgroundColor', '#666CFF');
        });
      document.title = this.company.name;
    },
    selectedLanguage(e) {
      if (e) {
        const el = e.target.closest(".navi-link");
        const lang = el.getAttribute("data-lang");
        i18nService.setActiveLanguage(lang);
        this.$emit(
          "language-changed",
          this.languages.find((val) => {
            return val.lang === lang;
          })
        );
        axios.post('api/user/change-language', { "code": lang }, {
          headers: self.headers
        }).then(function (response) {
          window.location.reload();
        }).finally(function (e) {
          window.location.reload();
        })
      }
    },
    isActiveLanguage(current) {
      return this.activeLanguage === current;
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
  },
  notifications: {
    showPointMessage: {
      title: 'Tebrikler',
      message: '1 puan kazandınız.',
      type: 'success',
      position: 'topRight'
    }
  },
  mounted() {
    this.superAdminToken = ls.get("superAdminToken")
    let self = this;
    this.getCompanyItem();
    this.getNotificationItems();
    this.getMessages();
    this.getMessagesCount();
    this.getNotifCount();
    this.selectedLanguage();

    EventBus.$on("message_count", () => this.getMessagesCount());

    let EchoServer = new Echo({
      broadcaster: "socket.io",
      host: process.env.VUE_APP_WEBSOCKET_URL,
      auth: {
        headers: {
          Authorization: "Bearer " + this.secureLocalStorage("token"),
        },
      },
      forceTLS: false,
      disableStats: true,
      encrypted: false,
      enabledTransports: ['ws', 'wss'],
    });

    let EchoServer2 = new Echo({
      broadcaster: "socket.io",
      host: process.env.VUE_APP_WEBSOCKET_URL,
      auth: {
        headers: {
          Authorization: "Bearer " + this.secureLocalStorage("token"),
        },
      },
      forceTLS: false,
      disableStats: true,
      encrypted: false,
      enabledTransports: ['ws', 'wss'],
    });

    if (this.userInfo && this.userInfo.company_id) {
      EchoServer.join(`online-users-${this.userInfo.company_id}`)
        .here((users) => {
          let online_users = _.uniqBy(_.values(users), "user");
          this.onlineUsers = users;
          this.$store.commit(
            COMPANY_MODULE_NAME + "/" + SET_ONLINE_USERS,
            online_users
          );
        })
        .joining((user) => {
          let users = _.values(this.online_users);
          let joinedUser = find((item) => item.user === user.user)(users);
          if (joinedUser) {
            this.$set(joinedUser, "status", true);
            user = joinedUser;
          }
          users.push(user);
          this.onlineUsers = users;
          let online_users = _.uniqBy(_.values(users), "user");
          this.$store.commit(
            COMPANY_MODULE_NAME + "/" + SET_ONLINE_USERS,
            online_users
          );
        })
        .leaving((user) => {
          let users = _.values(this.online_users);
          let leavedUser = find((item) => item.user === user.user)(users);
          if (leavedUser) {
            this.$set(leavedUser, "status", false);
            users.push(leavedUser);
            users = _.uniqBy(_.values(users), "user");
            setTimeout(() => {
              let leavedUser = find((item) => item.user === user.user)(users);
              if (leavedUser && leavedUser.status === false) {
                users = remove(
                  (item) => item.status === false && item.user === user.user
                )(users);
                this.onlineUsers = users;
              }
              this.$store.commit(
                COMPANY_MODULE_NAME + "/" + SET_ONLINE_USERS,
                users
              );
            }, 5000);
            this.$store.commit(
              COMPANY_MODULE_NAME + "/" + SET_ONLINE_USERS,
              users
            );
          }
        });

      EchoServer2.private('point-channel-' + this.userInfo.id)
        .listen('point-event', (data) => {
          self.showPointMessage(data.message);
        });
    }
  },
  watch: {
    $route(to, from) {
      let refs = ["profileRefs", "messageRefs", "notificationRef"];
      refs.map((item) => {
        UIkit.dropdown(this.$refs[item]).hide(0);
      });
    },
  },
};
</script>

<style scoped>
.btn-soft-info.disabled,
.btn-soft-info:disabled {
  color: #00b8d9 !important;
  background-color: #ccf1f7 !important;
  border-color: #ccf1f7 !important;
}

/* :root {
  --backgroundColor:unset;
} */
/* .header { */
/* background-color: var(--themeColor); */
/* } */
</style>