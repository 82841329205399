<template>
  <div class="container">
    <div class="footer">
      <div class="uk-grid-collapse" uk-grid>
        <div class="uk-width-expand@s uk-first-column d-flex align-item-center">
          <small><img :src="logoFooter" width="120" alt=""> © <strong>Softinya</strong>. {{
            $t('general.all_rights_reserved') }}.</small>
        </div>
        <div class="mt-2 mr-4">
          <a @click="openInformationUser()">{{ $t('general.clarification_Text') }}</a>
        </div>
        <div class="uk-width-auto@s d-flex align-items-center">

          <nav class="footer-nav-icon">
            <ul v-if="subdomain !== 'next4bizacademy.com'">
              <li><a href="https://tr.linkedin.com/company/anzeraogrenmeteknolojileri/" target="_blank"><i
                    class="icon-brand-linkedin"></i></a></li>
              <li><a href="https://www.instagram.com/anzeraofficial/" target="_blank"><i
                    class="icon-brand-instagram"></i></a></li>
              <li><a
                  href="https://tr-tr.facebook.com/pages/category/Computer-Company/Anzera-%C3%96%C4%9Frenme-Teknolojileri-601130579942407/"
                  target="_blank"><i class="icon-brand-facebook"></i></a></li>
              <li><a href="https://www.youtube.com/channel/UCYlNDP2rbHqxD95wbsOSyKw" target="_blank"><i
                    class="icon-brand-youtube"></i></a></li>
            </ul>
            <ul v-else>
              <li><a href="https://www.linkedin.com/company/next4biz/" target="_blank"><i
                    class="icon-brand-linkedin"></i></a></li>
              <li><a href="https://www.instagram.com/next4biz/" target="_blank"><i class="icon-brand-instagram"></i></a>
              </li>
              <li><a href="https://www.facebook.com/next4biz" target="_blank"><i class="icon-brand-facebook"></i></a>
              </li>
              <li><a href="https://www.youtube.com/@next4biz" target="_blank"><i class="icon-brand-youtube"></i></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div id="infoForUser" class="uk-modal" uk-modal>
      <div class="uk-modal-dialog bggray">
        <div class="uk-padding-small" uk-grid>
          <h4>{{ $t('general.clarification_Text') }}</h4>
        </div>
        <div class="row p-4">
          <div class="col-lg-12">
            <div v-if="activeLanguage === 'tr'" class="form-group row mb-2">
              Anzera Öğrenme Teknolojileri A.Ş. Kişisel Verilerin Korunması ve İşlenmesi Aydınlatma Metni <br>
              <hr>
              Bu aydınlatma metni, siz değerli kullanıcılarımızın Anzera Öğrenme Teknolojileri A.Ş.tarafından sağlanan
              hizmetlerimizi kullanırken kişisel verilerinizin nasıl işlendiği konusunda sizi bilgilendirmek amacıyla
              hazırlanmıştır. [Anzera Öğrenme Teknolojileri A.Ş.], Kişisel Verilerin Korunması Kanunu ("KVKK")
              hükümlerine tam uyum göstermekte ve kişisel verilerinizi gizli ve güvenli bir şekilde işlemek için gerekli
              önlemleri almaktadır.<br>
              1. Kişisel Verilerinizin İşlenme Amacı:
              Anzera Öğrenme Teknolojileri A.Ş., sunduğumuz hizmetleri sağlayabilmek, sizlere özel teklifler sunmak,
              pazarlama iletişimi yapmak, müşteri memnuniyetini artırmak, istatistiksel analizler yapmak gibi amaçlarla
              kişisel verilerinizi işleyebilir.<br>
              2. İşlenen Kişisel Verileriniz:
              Yukarıda bahsedilen amaçlarla işlediğimiz kişisel verileriniz aşağıdakileri içerebilir:<br>
              - İsim, adres, telefon numarası gibi temel iletişim bilgileri<br>
              - E-posta adresi, kullanıcı adı ve şifre gibi kimlik bilgileri<br>
              - Anzera Öğrenme Teknolojileri A.Ş.'deki etkileşimlerinizi takip etmek için çerezler ve benzeri
              teknolojiler<br>
              3. Kişisel Verilerinizin İşlenme Hukuki Sebebi:
              Kişisel verilerinizin işlenmesi, size sağlanan hizmetleri sunmak ve kanunen gerekliliklerini yerine
              getirmek, Anzera Öğrenme Teknolojileri A.Ş.'nin meşru çıkarlarını koruma amacıyla
              gerçekleştirilebilir.<br>
              4. Kişisel Verilerin Aktarımı:
              Kişisel verileriniz, yasal düzenlemeler gerektirdiği durumlarda veya hizmet sağlayıcılarımızla işbirliği
              yaptığımızda belirli koşullar altında paylaşılabilir. Bu durumda, verilerinizin gizliliği ve güvenliği
              için gerekli önlemlerin alındığından emin olacağız.<br>
              5. Kişisel Verilerinizin Saklanması:
              Kişisel verileriniz, KVKK ve ilgili mevzuata uygun olarak belirlenen süre boyunca saklanacaktır. Saklama
              süresi dolduğunda, kişisel verileriniz silinecek veya yok edilecektir.<br>
              6. Kişisel Veri Sahibi Hakları:
              KVKK kapsamında, kişisel veri sahipleri olarak aşağıdaki haklara sahipsiniz:<br>
              - Kişisel verilerinizin işlenip işlenmediğini öğrenme,<br>
              - İşlenen kişisel verilerinizi talep etme,<br>
              - İşlenen kişisel verilerinizin düzeltilmesini isteme,<br>
              - İşlenen kişisel verilerinizin silinmesini veya yok edilmesini isteme,<br>
              - İşlenen kişisel verilerinizin aktarıldığı üçüncü tarafların bilgilendirilmesini isteme,<br>
              - İşlenen kişisel verilerinize karşı bir itirazda bulunma,<br>
              - Otomatik sistemlerle işlenen kişisel verilerinizle ilgili bir kararın size karşı oluşturulmasını
              engelleme.<br>
              7. İletişim Bilgileri:
              Verilerin işlenmesi veya KVKK konusundaki sorularınızı sormak için lütfen aşağıdaki iletişim bilgilerini
              kullanın:<br>
              Anzera Öğrenme Teknolojileri A.Ş.<br>
              Büyükdere Cad. Onur İş Merkezi No:1/1 Kat:6  34394 Esentepe – Şişli/İstanbul<br>
              0212 267 53 73<br>
              info@anzera.com.tr<br>
              Bu aydınlatma metni, kişisel verilerinizin gizliliği ve güvenliği hakkında daha fazla bilgi sağlamaktadır.
              Herhangi bir sorunuz veya endişeniz varsa, lütfen bizimle iletişime geçmekten çekinmeyin.<br>
              Anzera Öğrenme Teknolojileri A.Ş. kişisel verilerinizi korumak ve gizliliğinizi sağlamak için gerekli tüm
              önlemleri almaktadır. Daha fazla bilgi için Gizlilik Politikamızı inceleyebilirsiniz.
            </div>
            <div v-if="activeLanguage === 'az'" class="form-group row mb-2">
              Anzera Öyrənmə Texnologiyaları A.Ş. Şəxsi Məlumatların Qorunması və Emalı haqqında
              <br>
              <hr>
              Bu məlumatlandırma mətni, siz dəyərli istifadəçilərimizi, Anzera Öyrənmə Texnologiyaları A.Ş. tərəfindən
              təqdim edilən xidmətlərdən istifadə edərkən şəxsi məlumatlarınızın necə emal edildiyi haqqında
              məlumatlandırmaq məqsədilə hazırlanmışdır. Anzera Öyrənmə Texnologiyaları A.Ş. Şəxsi Məlumatların
              Qorunması Qanununun ("KVKK") müddəalarına tam riayət edir və şəxsi məlumatlarınızı gizli və təhlükəsiz
              şəkildə emal etmək üçün zəruri tədbirləri görür.<br>
              1. Şəxsi Məlumatlarınızın Emal Məqsədi: Anzera Öyrənmə Texnologiyaları A.Ş., təqdim etdiyimiz xidmətləri
              təmin etmək, sizə xüsusi təkliflər təqdim etmək, müştəri məmnuniyyətini artırmaq, statistik təhlillər
              aparmaq kimi məqsədlərlə şəxsi məlumatlarınızı emal edə bilər.<br>
              2. Emal Edilən Şəxsi Məlumatlarınız: Yuxarıda göstərilən məqsədlərlə emal etdiyimiz şəxsi məlumatlarınıza
              aşağıdakılar daxil ola bilər:<br>
              - Ad, ünvan, mobil nömrəsi kimi əsas əlaqə məlumatları<br>
              - E-poçt ünvanı, istifadəçi adı və şifrə kimi şəxsiyyəti təsdiq edən məlumatlar<br>
              - Anzera Öyrənmə Texnologiyaları A.Ş.-dəki əlaqələrinizi izləmək üçün kukilər və oxşar texnologiyalar<br>
              3. Şəxsi Məlumatlarınızın Emalının Hüquqi Səbəbi: Şəxsi məlumatlarınız, sizə təqdim olunan xidmətləri
              göstərmək və qanunla tənzimlənən öhdəlikləri yerinə yetirmək, Anzera Öyrənmə Texnologiyaları A.Ş.-nin
              qanuni maraqlarını qorumaq məqsədilə emal edilə bilər.<br>
              4. Şəxsi Məlumatlarınızın ötürülməsi: Şəxsi məlumatlarınız, qanunvericilik tələblərinə uyğun olaraq və ya
              xidmət təminatçılarımızla əməkdaşlıq etdiyimiz hallarda müəyyən şərtlər altında paylaşa bilər. Bu halda,
              məlumatlarınızın gizliliyi və təhlükəsizliyi üçün zəruri tədbirlərin görüldüyündən əmin olacağıq.<br>
              5. Şəxsi Məlumatlarınızın Saxlanması: Şəxsi məlumatlarınız, KVKK və müvafiq qanunvericiliyə uyğun olaraq
              müəyyən edilmiş müddət ərzində saxlanılacaqdır. Saxlama müddəti bitdikdə, şəxsi məlumatlarınız silinəcək
              və ya məhv ediləcəkdir.<br>
              6. Şəxsi Məlumat Sahibi Hüquqları: KVKK çərçivəsində şəxsi məlumat sahibləri olaraq aşağıdakı hüquqlara
              maliksiniz:<br>
              - Şəxsi məlumatlarınızın emal edilib-edilmədiyini öyrənmək,<br>
              - Emal edilmiş şəxsi məlumatlarınızı tələb etmək,<br>
              - Emal edilmiş şəxsi məlumatlarınızın düzəldilməsini tələb etmək,<br>
              - Emal edilmiş şəxsi məlumatlarınızın silinməsini və ya məhv edilməsini tələb etmək,<br>
              - Emal edilmiş şəxsi məlumatlarınızın ötürüldüyü üçüncü şəxslərin məlumatlandırılmasını tələb etmək,<br>
              - Emal edilmiş şəxsi məlumatlarınıza qarşı etiraz etmək,<br>
              - Avtomatik sistemlərlə emal edilmiş şəxsi məlumatlarınızla bağlı qərarın sizə qarşı yönəldilməsinin
              qarşısını almaq.<br>
              7. Əlaqə Məlumatları: Məlumatların emalı və ya KVKK ilə bağlı suallarınızı soruşmaq üçün aşağıdakı əlaqə
              məlumatlarından istifadə edin:<br>
              Anzera Öyrənmə Texnologiyaları A.Ş.<br>
              Böyükdəmir Cad. Onur İş Merkezi No:1/1
              Mərtəbə:6 34394 Esentepe – Şişli/İstanbul<br>
              0212 267 53 73<br>
              info@anzera.com.tr<br>
              Bu məlumatlandırma mətni şəxsi məlumatlarınızın məxfiliyi və təhlükəsizliyi haqqında daha çox məlumat
              verir. Hər hansı sualınız və ya narahatlığınız varsa, bizimlə əlaqə saxlamaqdan çəkinməyin. Anzera Öyrənmə
              Texnologiyaları A.Ş., şəxsi məlumatlarınızı qorumaq və məxfiliyinizi təmin etmək üçün zəruri bütün
              tədbirləri görür. Ətraflı məlumat üçün Məxfilik Siyasətimizi nəzərdən keçirə bilərsiniz.
            </div>
            <div v-if="activeLanguage === 'en'" class="form-group row mb-2">
              Anzera Learning Technologies Inc. Personal Data Protection and Processing Information Notice<br>
              <hr>
              This information notice has been prepared to inform you, our valued users, about how your personal data is
              processed while using the services provided by Anzera Learning Technologies Inc. [Anzera Learning
              Technologies Inc.] fully complies with the provisions of the Personal Data Protection Law ("KVKK") and
              takes necessary measures to process your personal data confidentially and securely.<br>
              1. Purpose of Processing Your Personal Data: Anzera Learning Technologies Inc. may process your personal
              data for purposes such as providing the services we offer, presenting you with special offers, carrying
              out marketing communications, increasing customer satisfaction, and conducting statistical analyses.<br>
              2. Your Processed Personal Data: The personal data we process for the purposes mentioned above may
              include:<br>
              - Basic contact information such as name, address, phone number<br>
              - Identity information such as email address, username, and password<br>
              - Cookies and similar technologies to track your interactions with Anzera Learning Technologies Inc.<br>
              3. Legal Basis for Processing Your Personal Data: The processing of your personal data may be carried out
              for the purpose of providing you with the services offered, fulfilling legal requirements, and protecting
              the legitimate interests of Anzera Learning Technologies Inc.<br>
              4. Transfer of Your Personal Data: Your personal data may be shared under certain conditions, as required
              by legal regulations or when we collaborate with service providers. In such cases, we will ensure that
              necessary measures are taken to safeguard the confidentiality and security of your data.<br>
              5. Retention of Your Personal Data: Your personal data will be retained for the duration specified in KVKK
              and relevant legislation. Once the retention period has expired, your personal data will be deleted or
              destroyed.<br>
              6. Rights of Personal Data Owners: Under KVKK, as personal data owners, you have the following rights:<br>
              - To learn whether your personal data is being processed,<br>
              - To request information about your processed personal data,<br>
              - To request the correction of your processed personal data,<br>
              - To request the deletion or destruction of your processed personal data,<br>
              - To request that third parties to whom your personal data has been transferred be informed,<br>
              - To object to the processing of your personal data,<br>
              - To prevent a decision made solely based on automated systems regarding your processed personal data from
              being directed against you.<br>
              7. Contact Information: For questions regarding the processing of data or KVKK, please use the following
              contact information: Anzera Learning Technologies Inc. Büyükdere Cad. Onur İş Merkezi No:1/1 Floor:6 34394
              Esentepe – Şişli/Istanbul 0212 267 53 73 info@anzera.com.tr<br>
              Anzera Öğrenme Teknolojileri A.Ş.<br>
              Büyükdere Cad. Onur İş Merkezi No:1/1 Kat:6  34394 Esentepe – Şişli/İstanbul<br>
              0212 267 53 73<br>
              info@anzera.com.tr<br>
              This information notice provides further details on the confidentiality and security of your personal
              data. If you have any questions or concerns, please do not hesitate to contact us. Anzera Learning
              Technologies Inc. takes all necessary measures to protect your personal data and ensure your privacy. For
              more information, please review our Privacy Policy.
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right bggray">
          <button class="uk-button uk-button-default small uk-modal-close" type="button">
            <i class="uil-times"></i> {{ $t("general.close") }}
          </button>
        </div>
      </div>
    </div>
    <div id="informationForNext4biz" class="uk-modal" uk-modal>
      <div class="uk-modal-dialog bggray">
        <div class="uk-padding-small" uk-grid>
          <h4>Aydınlatma Metni</h4>
        </div>
        <div class="row p-4">
          <div class="col-lg-12">
            <div class="form-group row mb-2">
              Next4biz BİLGİ TEKNOLOJİLERİ A.Ş. KİŞİSEL VERİLERİN İŞLENMESİ AYDINLATMA METNİ<br>
              <hr>
              <div class="legacy__page-content_detail">
                <h3>Next4biz BİLGİ TEKNOLOJİLERİ A.Ş. KİŞİSEL VERİLERİN İŞLENMESİ AYDINLATMA METNİ</h3>
                <p>Next4biz Bilgi Teknolojileri A.Ş. (“Şirket”) olarak kişisel verilerinizin güvenliği hususuna azami
                  özen
                  gösterilmekte, ürün ve hizmetlerimizden faydalanan kişiler dahil, tüm şahıslara ait her türlü kişisel
                  veriler 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVK Kanunu”)’na uygun olarak işlenerek,
                  muhafaza
                  edilmektedir. KVK Kanunu’nda tanımlı şekli ile “Veri Sorumlusu” sıfatıyla, kişisel verilerinizi
                  aşağıda
                  izah edildiği surette ve mevzuat tarafından emredilen sınırlar çerçevesinde işlemekteyiz.</p>
                <p><strong>1.Kişisel Verilerin İşlenmesi Amaçları</strong></p>
                <p>Şirketimiz tarafından sağlanan hizmetler ve Şirketimizin ticari faaliyetlerine bağlı olarak ve bu iş
                  ve
                  hizmetlerin sunulabilmesi amacıyla kişisel verileriniz işlenmektedir.</p>
                <p>Toplanan kişisel verileriniz, Şirketimiz tarafından sunulan ürün ve hizmetlerden sizleri
                  faydalandırmak
                  için gerekli çalışmaların iş birimlerimiz tarafından yapılması, Şirketimiz tarafından sunulan ürün ve
                  hizmetlerin sizlerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek sizlere
                  önerilmesi, Şirketimizin, Şirketimizle iş ilişkisi içerisinde olan kişilerin hukuki ve ticari
                  güvenliğinin
                  temini, müşterileri değerlendirme/şikayet yönetimi süreçleri, itibar araştırma süreçleri, etkinlik
                  yönetimi, hukuki uyum süreci, denetim, mali işler v.b., Şirketimizin ticari ve iş stratejilerinin
                  belirlenmesi ve uygulanması ve Şirketimizin insan kaynakları politikalarının yürütülmesinin temini
                  amaçlarıyla KVK Kanunu’nun 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları
                  dahilinde işlenecektir.</p>
                <p><strong>2.İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği</strong></p>
                <p>Operasyonel süreçlerin yürütülmesi kapsamında kişisel veriler aktarılmamaktadır. Şirketimizin sunduğu
                  hizmetlerin gerektirdiği hallerde ve zorunlu olan ölçüde üçüncü parti iş ortaklarımızın veri
                  merkezlerinde, güvenli ortamlarda verilerin saklanması amacıyla kişisel verileriniz yurt içinde
                  işlenecektir.</p>
                <p><strong>3.Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</strong></p>
                <p>Şirketimiz hizmetlerini almak amacıyla firmanızın ve sizin sözleşme ilişkisine girmeniz, destek
                  merkezlerimizi veya internet sayfamızı kullanmanız, Şirketimizi veya internet sitemizi ziyaret
                  etmeniz,
                  Şirketimizin düzenlediği eğitim, seminer veya organizasyonlara katılmanız halinde dijital ve manuel
                  yöntemler ile toplanmaktadır. Kişisel verileri toplamamızın hukuki sebebi Kanunun 5. Maddesinde
                  gösterilen
                  iş ve hizmet ilişkisinin gereği olan edimin ifası ve yine Kanunun 5. ve 6. maddelerinde belirtilen
                  ilgili
                  kişinin açık rızasıdır.</p>
                <p><strong>4.Kişisel Veri Sahibinin KVK Kanunu’nun 11. maddesinde Sayılan Hakları</strong></p>
                <p>Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi, işbu Aydınlatma Metninde aşağıda
                  düzenlenen yöntemlerle Şirketimize iletmeniz durumunda Şirketimiz talebin niteliğine göre en kısa
                  sürede
                  ve en geç otuz gün içinde talebiniz ücretsiz olarak sonuçlandıracaktır. Ancak, Kişisel Verileri Koruma
                  Kurulunca bir ücret öngörülmesi halinde, Şirketimiz tarafından belirlenen tarifedeki ücret
                  alınacaktır. Bu
                  kapsamda kişisel veri sahipleri;</p>
                <ul>
                  <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
                  <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                  <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
                  </li>
                  <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                  <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu
                    kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                  <li>KVK Kanunu’nun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen,
                    işlenmesini
                    gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini
                    isteme
                    ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                  </li>
                  <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin
                    kendisi
                    aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
                  <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın
                    giderilmesini talep etme haklarına sahiptir.</li>
                </ul>
                <p>KVK Kanunu’nun 13. maddesinin 1. fıkrası gereğince, yukarıda belirtilen haklarınızı kullanmak ile
                  ilgili
                  talebinizi, yazılı ve imzalı olarak olarak: Next4biz Bilgi Teknolojileri A.Ş., Sahrayı Cedit mah. Halk
                  Sk.
                  No:40/4 Kat 4 PakPen Plaza Kadıköy İstanbul adresine veya <a href="mailto:next4biz@hs03.kep.tr"
                    target="_blank" rel="noopener">next4biz@hs03.kep.tr</a> kayıtlı elektronik posta adresimize güvenli
                  elektronik imza ile imzalanmış olarak gönderebilirsiniz. Kişisel veri sahibi olarak sahip olduğunuz ve
                  yukarıda belirtilen haklarınızı kullanmak için yapacağınız ve kullanmayı talep ettiğiniz hakka ilişkin
                  açıklamalarınızı içeren başvuruda; talep ettiğiniz hususun açık ve anlaşılır olması, talep ettiğiniz
                  konunun şahsınız ile ilgili olması veya başkası adına hareket ediyor iseniz bu konuda özel olarak
                  yetkili
                  olmanız ve yetkinizi belgelendirilmesi, ayrıca KVKK talebiniz konusunda kullanılmak
                  üzere&nbsp;iletişim&nbsp;bilgilerini içermesi ve başvuruya kimliğinizi tevsik edici belgelerin
                  eklenmesi
                  gerekmektedir.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right bggray">
          <button class="uk-button uk-button-default small uk-modal-close" type="button">
            <i class="uil-times"></i> {{ $t("general.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18nService from "@/core/services/i18n.service.js";
export default {
  name: "Footer",
  props: {
    showMenu: {
      required: false,
      default: true
    },
  },
  data() {
    return {
      subdomain: null
    }
  },
  computed: {
    logoFooter() {
      return process.env.BASE_URL + "media/images/new-logo-dark.svg";
    },
    activeLanguage() {
      return i18nService.getActiveLanguage();
    },
  },
  methods: {
    openInformationUser() {
      if (this.subdomain === 'next4bizacademy.com')
        UIkit.modal("#informationForNext4biz").show();
      else {
        UIkit.modal("#infoForUser").show();
      }
    }
  },
  mounted() {
    this.subdomain = window.location.hostname
  },
}
</script>

<style scoped>
.footer small {
  font-weight: 300;
}
</style>